<template>
    <div class="main-contents display terms ncs">
        <div class="tit" v-if="this.abilSeq ==''">능력단위 등록<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="movePage('back')"/></div>
        <div class="tit" v-else>능력단위 수정<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="movePage('back')" /></div>

        <div class="sub-tit" v-if="this.abilSeq ==''"><p>능력단위 등록</p></div>
        <div class="sub-tit" v-else><p>능력단위 수정</p></div>

        <div class="banner_box">
            <div class="list">
                <div class="label">중분류명</div>
                <div v-if="this.abilSeq == ''">
                    <SelectComp :list="midNameList" codeKey="midClassCd" nameKey="midClassNm" title="중분류" v-model="abilDetail.midClassCd" @change="loadMinor()" :rules="{'required':true}" maxByte="4" />
                </div>
                <div v-else>
                    <SelectComp :list="midNameList" codeKey="midClassCd" nameKey="midClassNm" v-model="abilDetail.midClassCd" :disabled="abilSeq != ''" />
                </div>
                <div class="label">레벨</div><InputComp type="text" placeholder="레벨" v-model="abilDetail.levelCd" tagName="div" maxByte="2" :rules="{'required':true}" title="레벨" />
                <div class="label">사용여부</div>
                <SelectComp list="Y:사용,N:사용안함" v-model="abilDetail.useYn" class="wd01" title="사용여부" :rules="{'required':true}" maxByte="1" />
            </div>
            <div class="list">
                <div class="label">소분류명</div>
                <div v-if="this.abilSeq == ''">
                    <SelectComp :list="minorNameList" codeKey="minorClassCd" nameKey="minorClassNm" v-model="abilDetail.minorClassCd" title="소분류" @change="loadDetail()" :rules="{'required':true}" maxByte="6" />
                </div>
                <div v-else>
                    <SelectComp :list="minorNameList" codeKey="minorClassCd" nameKey="minorClassNm" v-model="abilDetail.minorClassCd" :disabled="abilSeq != ''" />
                </div>
                <div class="label">능력단위코드</div><InputComp type="text" placeholder="능력단위코드" v-model="abilDetail.abilUnitCd" :rules="{'required':true}" tagName="div" maxByte="30" title="능력단위코드" />
            </div>
            <div class="list">
                <div class="label">세분류명</div>
                <div v-if="this.abilSeq == ''">
                        <SelectComp :list="detailNameList" codeKey="detailClassCd" nameKey="detailClassNm" title="세분류" v-model="abilDetail.detailClassCd" :rules="{'required':true}" maxByte="8" />
                </div>
                <div v-else>
                        <SelectComp :list="detailNameList" codeKey="detailClassCd" nameKey="detailClassNm" v-model="abilDetail.detailClassCd" :disabled="abilSeq != ''" />
                </div>
                <div class="label">능력단위명</div><InputComp type="text" placeholder="능력단위명" v-model="abilDetail.abilUnitNm" tagName="div" :rules="{'required':true}" maxByte="100" title="능력단위명" />
            </div>
        </div>
        <div class="select_btn">
            <div class="btn_01" @click="movePage('back')">취소</div>
            <div class="btn_02" v-if="this.abilSeq ==''" @click="openPopup()">등록</div>
            <div class="btn_02" v-else style="background-color: #FF681D;" @click="openPopup()">수정</div>
        </div>
    </div>
</template>

<script>
import SelectComp from "@/components/base/SelectComp.vue";
import InputComp from "@/components/base/InputComp.vue";

export default {
    data() {
        return {
            abilDetail: {
                midClassCd: '',
                minorClassCd: '',
                detailClassCd: '',
                levelCd: '',
                abilUnitCd: '',
                abilUnitNm: '',
                useYn: '',
                abilSeq : '',
            },
            abilSeq: this.$route.params.abilSeq || '',
            midNameList : [],
            minorNameList : [],
            detailNameList : [],

        }
    },
    beforeMount() {
        // console.log('PARAM RESULT ', this.abilSeq);
        this.loadMid();
        if (this.abilSeq != "") {
            this.loadMinor();
            this.loadDetail();
            this.getAbilInfo();
            this.abilDetail.abilSeq = this.abilSeq;
        }
    },
    methods: {
        getAbilInfo() {
            var param = {};
            param.abilSeq = this.abilSeq;
            this.$.httpPost("/api/main/adm/ncsinout/getAbilUnitInfo", { abilSeq: this.abilSeq })
                .then(res => {
                // console.log("getAbilUnitInfo Result", res);
                this.abilDetail = res.data.abilInfo || {};
                // console.log('real?' , this.abilDetail);
            }).catch(this.$.httpErrorCommon);
        },
        movePage(div) {
            if (div == "back")
                this.$router.go(-1);
            else
                this.$router.push("MAN936M02");
        },

        loadMid() {
            this.$.httpPost('/api/main/adm/ncsinout/loadMidList')
                .then(res => {
                    // console.log('loadMidList RESULT', res);
                    this.midNameList = res.data.loadMidList;
                }).catch(this.$.httpErrorCommon);
        },

        loadMinor() {
            this.minorNameList = [];
            this.abilDetail.minorClassCd = '';
            this.detailNameList = [];
            this.abilDetail.detailClassCd = '';

            var params = {};
                params.midClassCd = this.abilDetail.midClassCd;
                this.$.httpPost('/api/main/adm/ncsinout/loadMinorList' , params)
                    .then(res => {
                        // console.log('loadMinorList RESULT', res);
                        // console.log(22222);
                        // console.log(res);
                        this.minorNameList = res.data.loadMinorList;
                    }).catch(this.$.httpErrorCommon);
        },

        loadDetail() {
            this.detailNameList = [];
            this.abilDetail.detailClassCd = '';
            var params = {};
            params.minorClassCd = this.abilDetail.minorClassCd;

            this.$.httpPost('/api/main/adm/ncsinout/loadDetailList' , params)
                .then(res => {
                    // console.log('loadDetailList RESULT', res);
                    this.detailNameList = res.data.loadDetailList;
                }).catch(this.$.httpErrorCommon);
        },

        openPopup() {
            var param = {};
            param.abilSeq = this.abilDetail.detailClassCd;
            param.abilDetail = this.abilDetail;
            // console.log('param', param);

            this.$.popup('/adm/man/MAN936P01', param)
                .then(res => {
                    if(res) {
                        this.$.httpPost('/api/main/adm/ncsinout/setAbilUnit', this.abilDetail)
                            .then(() => {
                                this.movePage();
                            }).catch(this.$.httpErrorCommon);
                    }
                })

        }
    },
    components: { SelectComp, InputComp }
}
    
</script>